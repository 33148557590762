<template>
  <!-- 公交2.0 实时调度 顶部-关注线路-模块 -->
  <div class="scheduling-header">
    <el-select
      filterable
      @change="selectChange"
      v-model="lineCodeList"
      multiple
      collapse-tags
      placeholder="请选择或搜索线路"
      class="select"
      value-key="companyId"
    >
      <el-option
        v-for="item in lineOptions"
        :key="item.companyId"
        :label="item.companyName"
        :value="item"
      ></el-option>
    </el-select>
    <div class="line-list">
      <el-button
        :type="currentIndex == index ? 'primary' : ''"
        size="small"
        v-for="(item, index) in lineCodeList"
        :key="index"
        @click="switchTabs(item, index)"
        :disabled="loading"
        class="line-item"
        >{{ item.companyName }}</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  queryAllLineList,
  saveFollowLineAPI,
  queryFollowLineAPI
} from "@/api/lib/bus-api.js";
export default {
  data () {
    return {
      lineCodeList: [],
      lineOptions: [],
      currentIndex: 0,
      currentLineObj: null,
      loading:false,
    };
  },
  created(){
    this.$bus.$on("loadingUpdate",(value)=>{
      this.loading = value;
    })
  },
  mounted () {
    this.getLineList(); //搜索框查询全部可选线路
    this.getLines();
  },
  watch: {
    currentIndex (n, o) {
      this.currentLineObj = this.lineCodeList[n];
      this.$emit("queryCurrentLine", this.currentLineObj);
    }
  },
  methods: {
    getLines () {
      queryFollowLineAPI().then(res => {
        if (res.code === 1000) {
          this.lineCodeList = res.data || [];
          this.currentLineObj = res.data[this.currentIndex];
          this.$emit("queryCurrentLine", this.currentLineObj);
        }
      });
    },
    selectChange (e) {
      if (e.length) {
        this.lineCodeList = e;
        saveFollowLineAPI({ lineList: e }).then(res => {
          if (res.code != 1000) {
            this.$message.warning(res.msg);
          }
          this.currentIndex = 0;
          this.getLines();
        });
      }
    },
    switchTabs (row, index) {
      if (index === this.currentIndex) return;
      this.currentIndex = index;
      this.currentLineObj = row;
      this.$emit("queryCurrentLine", this.currentLineObj);
    },
    //搜索框查询全部可选线路
    getLineList () {
      queryAllLineList().then(res => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.lineOptions = res.data;
          }
        }
      });
    }
  },
  destroyed () { }
};
</script>
<style lang="scss" scoped>
.scheduling-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 20px 0;
  .line-list {
    flex: 1;
    display: flex;
    justify-content: center;
    .line-item {
      padding: 0 22px;
      border-radius: 4px;
      height: 32px;
      line-height: 32px;
      margin-right: 4px;
      font-size: 16px;
    }
    .active {
      background: #f78d42;
    }
  }
}
</style>
