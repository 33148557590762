var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "simulation-map-box", style: { height: _vm.containHeight } },
    [
      _c(
        "div",
        { staticClass: "simulation-map-between left" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("主场")]),
          _c("div", { staticClass: "main-line" }, [
            _c(
              "div",
              { staticClass: "item-cph" },
              _vm._l(_vm.dispatchPlanList.masterPlan, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-car",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function($event) {
                        return _vm.handleDragStart($event, item)
                      },
                      dragover: function($event) {
                        $event.preventDefault()
                        return _vm.handleDragOver($event, item)
                      },
                      dragenter: function($event) {
                        return _vm.handleDragEnter($event, item)
                      },
                      dragend: function($event) {
                        return _vm.handleDragEnd($event, item)
                      }
                    }
                  },
                  [
                    _c("i", { class: _vm.getBusIcon(item.natureState) }),
                    _c(
                      "el-popover",
                      { attrs: { placement: "right", trigger: "click" } },
                      [
                        _c("div", { staticClass: "inner-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction("sendMessage")
                                }
                              }
                            },
                            [_vm._v(" 消息下发 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "sendPlanText",
                                    item.id
                                  )
                                }
                              }
                            },
                            [_vm._v(" 下发行车计划 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "changeDriverPop",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 调整计划 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "manualStart",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 手动发车 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "changeStatus",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 修改运行状态 ")]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "cph",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [_vm._v(_vm._s(item.cph.slice(2)))]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "item-cph time-style" },
              _vm._l(_vm.dispatchPlanList.masterPlanTime, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  { key: index + "t", staticClass: "list-car" },
                  [
                    _c(
                      "div",
                      {
                        class: item.overTime ? "overtime" : "time",
                        on: {
                          click: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "top",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            return _vm.handleChangeTime(
                              item.beginTime,
                              index,
                              1
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.beginTime) + " ")]
                    )
                  ]
                )
              }),
              0
            )
          ]),
          _c(
            "el-button",
            {
              staticClass: "submit-btn",
              attrs: {
                size: "mini",
                type: _vm.masterDrag ? "primary" : "info",
                disabled: !_vm.masterDrag
              },
              on: { click: _vm.submitDrag }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "simulation-map-center" }, [
        _c("div", { staticClass: "center-logo" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(_vm.activeName) + " ")
          ]),
          _c("div", { staticClass: "online" }, [
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("线路车辆")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.vehicleStatistics.vehicleCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("营运")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.vehicleStatistics.operateCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("非营运")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.vehicleStatistics.notOperateCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("上行")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.vehicleStatistics.upCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("下行")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.vehicleStatistics.downCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("计划趟次")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.lineTripsStatistics.planCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("已完成")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.lineTripsStatistics.completeCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("未完成")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.lineTripsStatistics.notCompleteCount))
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("发车准点率")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.planTimelyData.startPunctual) + "%")
              ])
            ]),
            _c("div", { staticClass: "car-data" }, [
              _c("span", [_vm._v("到达准点率")]),
              _c("span", { staticClass: "num-value" }, [
                _vm._v(_vm._s(_vm.planTimelyData.endPunctual) + "%")
              ])
            ])
          ]),
          _c("div", { staticClass: "menus" }, [
            _c(
              "div",
              {
                class: [
                  { "menu-active": _vm.currentMap == "simulate" },
                  "map-menu",
                  "simulate-btn"
                ],
                on: {
                  click: function($event) {
                    return _vm.switchMAp("simulate")
                  }
                }
              },
              [_vm._v(" 模拟视图 ")]
            ),
            _c(
              "div",
              {
                class: [
                  { "menu-active": _vm.currentMap == "corpuscle" },
                  "map-menu",
                  "corpuscle-btn"
                ],
                on: {
                  click: function($event) {
                    return _vm.switchMAp("corpuscle")
                  }
                }
              },
              [_vm._v(" 电子地图 ")]
            )
          ])
        ]),
        _c("div", { staticClass: "switch-contain" }, [
          _vm.currentMap == "simulate"
            ? _c("div", { staticClass: "simulation-map" }, [
                _c("div", { staticClass: "up" }, [
                  _c("div", { staticClass: "line-center" }),
                  _c(
                    "ul",
                    { ref: "upUl" },
                    [
                      _vm._l(_vm.zhuTofuOptions, function(item) {
                        return _c("li", { key: item.stationNo }, [
                          _c("i"),
                          _c("span", [_vm._v(_vm._s(item.stationName))])
                        ])
                      }),
                      _c("div", { staticClass: "bus-up" }, [
                        _c(
                          "div",
                          { ref: "up", staticClass: "line" },
                          _vm._l(_vm.downBus, function(bus, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cus-dropdown",
                                style:
                                  "left:" +
                                  _vm.upStationSpace *
                                    (bus.stationNo -
                                      1 +
                                      (bus.arriveType == "1" ? 0 : 1) * 0.5) +
                                  "px",
                                attrs: {
                                  "staion-num": bus.stationNo,
                                  "arrive-type": bus.arriveType
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-dropdown-link",
                                    on: { click: _vm.onDropDownClick }
                                  },
                                  [
                                    _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        staticStyle: { "z-index": "10" },
                                        attrs: {
                                          value: bus.busCodes.length,
                                          hidden: bus.busCodes.length == 1
                                        }
                                      },
                                      [
                                        _c("i", {
                                          class: _vm.getBusIcon(
                                            bus.busCodes[0].status
                                          )
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "ul",
                                  {
                                    staticClass: "dropdown-list dropdown-down",
                                    style: {
                                      zIndex: _vm.selfPage ? "9999" : "100"
                                    }
                                  },
                                  _vm._l(bus.busCodes, function(code) {
                                    return _c(
                                      "li",
                                      {
                                        key: code.busCodes,
                                        attrs: { vehicleNo: code.vehicleNo }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(code.busCode.slice(2)) +
                                            " "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "down" }, [
                  _c("div", { staticClass: "line-center" }),
                  _c(
                    "ul",
                    { ref: "downUl" },
                    [
                      _vm._l(_vm.fuToZhuOptions, function(item) {
                        return _c("li", { key: item.stationNo }, [
                          _c("i"),
                          _c("span", [_vm._v(_vm._s(item.stationName))])
                        ])
                      }),
                      _c("div", { staticClass: "bus-down" }, [
                        _c(
                          "div",
                          { ref: "down", staticClass: "line" },
                          _vm._l(_vm.upBus, function(bus, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "cus-dropdown",
                                style:
                                  "left:" +
                                  (_vm.ulWidth -
                                    22 -
                                    _vm.downStationSpace *
                                      (bus.stationNo -
                                        1 +
                                        (bus.arriveType == "1" ? 0 : 1) * 0.5) +
                                    1) +
                                  "px",
                                attrs: {
                                  "staion-num": bus.stationNo,
                                  "arrive-type": bus.arriveType
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-dropdown-link",
                                    on: { click: _vm.onDropDownClick }
                                  },
                                  [
                                    _c(
                                      "el-badge",
                                      {
                                        staticClass: "item",
                                        staticStyle: { "z-index": "10" },
                                        attrs: {
                                          value: bus.busCodes.length,
                                          hidden: bus.busCodes.length == 1
                                        }
                                      },
                                      [
                                        _c("i", {
                                          class: _vm.getBusIcon(
                                            bus.busCodes[0].status
                                          )
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "ul",
                                  {
                                    ref: "dropdownList",
                                    refInFor: true,
                                    staticClass: "dropdown-list dropdown-up",
                                    style: {
                                      zIndex: _vm.selfPage ? "9999" : "100"
                                    }
                                  },
                                  _vm._l(bus.busCodes, function(code) {
                                    return _c(
                                      "li",
                                      {
                                        key: code.busCodes,
                                        attrs: { vehicleNo: code.vehicleNo }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(code.busCode.slice(2)) +
                                            " "
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    2
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentMap == "corpuscle",
                  expression: "currentMap == 'corpuscle'"
                }
              ],
              staticClass: "corpuscle-map"
            },
            [_c("div", { staticClass: "myMap", attrs: { id: "container" } })]
          )
        ]),
        _c("div", { staticClass: "map-notOperateList" }, [
          _c(
            "div",
            { staticClass: "logo" },
            _vm._l(_vm.hintOptions, function(item, index) {
              return _c("div", { key: index, staticClass: "item" }, [
                _c("img", { attrs: { src: item.url, alt: "" } }),
                _c("span", [_vm._v(_vm._s(item.val))]),
                index == 1 && _vm.lineTodayOfflineCarData
                  ? _c("span", { staticStyle: { "margin-left": "6px" } }, [
                      _vm._v("( " + _vm._s(_vm.lineTodayOfflineCarData) + " )")
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "simulation-map-between right" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("副场")]),
          _c("div", { staticClass: "main-line" }, [
            _c(
              "div",
              { staticClass: "item-cph" },
              _vm._l(_vm.dispatchPlanList.slaverPlan, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-car",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function($event) {
                        return _vm.handleDragStart2($event, item)
                      },
                      dragover: function($event) {
                        $event.preventDefault()
                        return _vm.handleDragOver2($event, item)
                      },
                      dragenter: function($event) {
                        return _vm.handleDragEnter2($event, item)
                      },
                      dragend: function($event) {
                        return _vm.handleDragEnd2($event, item)
                      }
                    }
                  },
                  [
                    _c("i", { class: _vm.getBusIcon(item.natureState) }),
                    _c(
                      "el-popover",
                      { attrs: { placement: "left", trigger: "click" } },
                      [
                        _c("div", { staticClass: "inner-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction("sendMessage")
                                }
                              }
                            },
                            [_vm._v(" 消息下发 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "sendPlanText",
                                    item.id
                                  )
                                }
                              }
                            },
                            [_vm._v(" 下发行车计划 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "changeDriverPop",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 调整计划 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "manualStart",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 手动发车 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "inner-item",
                              on: {
                                click: function($event) {
                                  return _vm.issueInstruction(
                                    "changeStatus",
                                    item
                                  )
                                }
                              }
                            },
                            [_vm._v(" 修改运行状态 ")]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "cph",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [_vm._v(_vm._s(item.cph.slice(2)))]
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "item-cph time-style" },
              _vm._l(_vm.dispatchPlanList.slaverPlanTime, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  { key: index + "t", staticClass: "list-car" },
                  [
                    _c(
                      "div",
                      {
                        class: item.overTime ? "overtime" : "time",
                        on: {
                          click: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "top",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            return _vm.handleChangeTime(
                              item.beginTime,
                              index,
                              2
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(item.beginTime) + " ")]
                    )
                  ]
                )
              }),
              0
            )
          ]),
          _c(
            "el-button",
            {
              staticClass: "submit-btn",
              attrs: {
                size: "mini",
                type: _vm.slaverDrag ? "primary" : "info",
                disabled: !_vm.slaverDrag
              },
              on: { click: _vm.submitDragSlaver }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _vm.timeDialog
        ? _c(
            "el-dialog",
            {
              staticClass: "timeDialog",
              attrs: {
                title: "修改发车时间",
                visible: _vm.timeDialog,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.timeDialog = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.form,
                    "label-width": "102px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发车时间：", prop: "addTime" } },
                    [
                      _c("el-time-picker", {
                        attrs: {
                          format: "HH:mm",
                          "value-format": "HH:mm",
                          placeholder: "发车时间"
                        },
                        model: {
                          value: _vm.form.addTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "addTime", $$v)
                          },
                          expression: "form.addTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.timeDialog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.saveTime }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }