<template>
  <!-- 公交2.0 实时调度 模拟视图和电子地图 -->
  <div class="simulation-map-box" :style="{height:containHeight}">
    <div class="simulation-map-between left">
      <div class="title">主场</div>
      <div class="main-line">
        <div class="item-cph">
          <div
            v-for="(item, index) in dispatchPlanList.masterPlan"
            :key="index"
            class="list-car"
            draggable="true"
            @dragstart="handleDragStart($event, item)"
            @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
            @dragend="handleDragEnd($event, item)"
          >
            <i :class="getBusIcon(item.natureState)"></i>
            <el-popover placement="right" trigger="click">
              <div class="inner-box">
                <div
                  class="inner-item"
                  @click="issueInstruction('sendMessage')"
                >
                  消息下发
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('sendPlanText', item.id)"
                >
                  下发行车计划
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('changeDriverPop', item)"
                >
                  调整计划
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('manualStart', item)"
                >
                  手动发车
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('changeStatus', item)"
                >
                  修改运行状态
                </div>
              </div>
              <div slot="reference" class="cph">{{ item.cph.slice(2) }}</div>
            </el-popover>
          </div>
        </div>
        <div class="item-cph time-style">
          <div
            class="list-car"
            v-for="(item, index) in dispatchPlanList.masterPlanTime"
            :key="index + 't'"
          >
            <div
              :class="item.overTime ? 'overtime' : 'time'"
              @click.top="handleChangeTime(item.beginTime, index, 1)"
            >
              {{ item.beginTime }}
            </div>
          </div>
          <!-- 时间不可拖动 -->
        </div>
      </div>
      <el-button
        class="submit-btn"
        size="mini"
        :type="masterDrag ? 'primary' : 'info'"
        @click="submitDrag"
        :disabled="!masterDrag"
        >保存</el-button
      >
    </div>
    <div class="simulation-map-center">
      <div class="center-logo">
        <div class="name">
          {{ activeName }}
        </div>
        <div class="online">
          <div class="car-data">
            <span>线路车辆</span>
            <span class="num-value">{{ vehicleStatistics.vehicleCount }}</span>
          </div>
          <div class="car-data">
            <span>营运</span>
            <span class="num-value">{{ vehicleStatistics.operateCount }}</span>
          </div>
          <div class="car-data">
            <span>非营运</span>
            <span class="num-value">{{
              vehicleStatistics.notOperateCount
            }}</span>
          </div>
          <div class="car-data">
            <span>上行</span>
            <span class="num-value">{{ vehicleStatistics.upCount }}</span>
          </div>
          <div class="car-data">
            <span>下行</span>
            <span class="num-value">{{ vehicleStatistics.downCount }}</span>
          </div>
          <div class="car-data">
            <span>计划趟次</span>
            <span class="num-value">{{ lineTripsStatistics.planCount }}</span>
          </div>
          <div class="car-data">
            <span>已完成</span>
            <span class="num-value">{{
              lineTripsStatistics.completeCount
            }}</span>
          </div>
          <div class="car-data">
            <span>未完成</span>
            <span class="num-value">{{
              lineTripsStatistics.notCompleteCount
            }}</span>
          </div>
          <div class="car-data">
            <span>发车准点率</span>
            <span class="num-value">{{ planTimelyData.startPunctual }}%</span>
          </div>
          <div class="car-data">
            <span>到达准点率</span>
            <span class="num-value">{{ planTimelyData.endPunctual }}%</span>
          </div>
        </div>
        <div class="menus">
          <div
            :class="[
              { 'menu-active': currentMap == 'simulate' },
              'map-menu',
              'simulate-btn',
            ]"
            @click="switchMAp('simulate')"
          >
            模拟视图
          </div>
          <div
            :class="[
              { 'menu-active': currentMap == 'corpuscle' },
              'map-menu',
              'corpuscle-btn',
            ]"
            @click="switchMAp('corpuscle')"
          >
            电子地图
          </div>
        </div>
      </div>
      <div class="switch-contain">
        <!-- 模拟视图 -->
        <div class="simulation-map" v-if="currentMap == 'simulate'">
          <!-- 下行- 主到副 -->
          <div class="up">
            <div class="line-center"></div>
            <ul ref="upUl">
              <li v-for="item in zhuTofuOptions" :key="item.stationNo">
                <i></i>
                <span>{{ item.stationName }}</span>
              </li>
              <div class="bus-up">
                <div class="line" ref="up">
                  <div
                    class="cus-dropdown"
                    v-for="(bus, index) in downBus"
                    :key="index"
                    :staion-num="bus.stationNo"
                    :arrive-type="bus.arriveType"
                    :style="
                      'left:' +
                      upStationSpace *
                        (bus.stationNo -
                          1 +
                          (bus.arriveType == '1' ? 0 : 1) * 0.5) +
                      'px'
                    "
                  >
                    <span class="el-dropdown-link" @click="onDropDownClick">
                      <el-badge
                        :value="bus.busCodes.length"
                        class="item"
                        style="z-index: 10"
                        :hidden="bus.busCodes.length == 1"
                      >
                        <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                      </el-badge>
                    </span>
                    <ul
                      class="dropdown-list dropdown-down"
                      :style="{ zIndex: selfPage ? '9999' : '100' }"
                    >
                      <li
                        v-for="code in bus.busCodes"
                        :key="code.busCodes"
                        :vehicleNo="code.vehicleNo"
                      >
                        {{ code.busCode.slice(2) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
          <!-- 下线 -->
          <div class="down">
            <div class="line-center"></div>
            <ul ref="downUl">
              <li v-for="item in fuToZhuOptions" :key="item.stationNo">
                <i></i>
                <span>{{ item.stationName }}</span>
              </li>
              <div class="bus-down">
                <div class="line" ref="down">
                  <div
                    class="cus-dropdown"
                    v-for="(bus, index) in upBus"
                    :key="index"
                    :staion-num="bus.stationNo"
                    :arrive-type="bus.arriveType"
                    :style="
                      'left:' +
                      (ulWidth -
                        22 -
                        downStationSpace *
                          (bus.stationNo -
                            1 +
                            (bus.arriveType == '1' ? 0 : 1) * 0.5) +
                        1) +
                      'px'
                    "
                  >
                    <span class="el-dropdown-link" @click="onDropDownClick">
                      <el-badge
                        :value="bus.busCodes.length"
                        class="item"
                        style="z-index: 10"
                        :hidden="bus.busCodes.length == 1"
                      >
                        <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                      </el-badge>
                    </span>
                    <ul
                      class="dropdown-list dropdown-up"
                      :style="{ zIndex: selfPage ? '9999' : '100' }"
                      ref="dropdownList"
                    >
                      <li
                        v-for="code in bus.busCodes"
                        :key="code.busCodes"
                        :vehicleNo="code.vehicleNo"
                      >
                        {{ code.busCode.slice(2) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <!-- 电子地图 -->
        <div class="corpuscle-map" v-show="currentMap == 'corpuscle'">
          <div id="container" class="myMap"></div>
        </div>
      </div>
      <div class="map-notOperateList">        
        <div class="logo">
          <div v-for="(item, index) in hintOptions" :key="index" class="item">
            <img :src="item.url" alt="" />
            <span>{{ item.val }}</span>
            <span v-if="index==1 && lineTodayOfflineCarData" style="margin-left:6px;">( {{lineTodayOfflineCarData}} )</span>
          </div>
        </div>
      </div>
    </div>
    <div class="simulation-map-between right">
      <div class="title">副场</div>
      <div class="main-line">
        <div class="item-cph">
          <div
            v-for="(item, index) in dispatchPlanList.slaverPlan"
            :key="index"
            class="list-car"
            draggable="true"
            @dragstart="handleDragStart2($event, item)"
            @dragover.prevent="handleDragOver2($event, item)"
            @dragenter="handleDragEnter2($event, item)"
            @dragend="handleDragEnd2($event, item)"
          >
            <i :class="getBusIcon(item.natureState)"></i>
            <el-popover placement="left" trigger="click">
              <div class="inner-box">
                <div
                  class="inner-item"
                  @click="issueInstruction('sendMessage')"
                >
                  消息下发
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('sendPlanText', item.id)"
                >
                  下发行车计划
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('changeDriverPop', item)"
                >
                  调整计划
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('manualStart', item)"
                >
                  手动发车
                </div>
                <div
                  class="inner-item"
                  @click="issueInstruction('changeStatus', item)"
                >
                  修改运行状态
                </div>
              </div>
              <div slot="reference" class="cph">{{ item.cph.slice(2) }}</div>
            </el-popover>
          </div>
        </div>
        <div class="item-cph time-style">
          <div
            class="list-car"
            v-for="(item, index) in dispatchPlanList.slaverPlanTime"
            :key="index + 't'"
          >
            <div
              :class="item.overTime ? 'overtime' : 'time'"
              @click.top="handleChangeTime(item.beginTime, index, 2)"
            >
              {{ item.beginTime }}
            </div>
          </div>
          <!-- 时间不可拖动 -->
        </div>
      </div>
      <el-button
        class="submit-btn"
        size="mini"
        :type="slaverDrag ? 'primary' : 'info'"
        @click="submitDragSlaver"
        :disabled="!slaverDrag"
        >保存</el-button
      >
    </div>
    <!-- 修改时间 -->
    <el-dialog
      title="修改发车时间"
      :visible.sync="timeDialog"
      v-if="timeDialog"
      width="30%"
      class="timeDialog"
    >
      <el-form
        :model="form"
        label-width="102px"
        :rules="rules"
        class="demo-form dialog-form"
        ref="form"
      >
        <el-form-item label="发车时间：" prop="addTime">
          <el-time-picker
            v-model="form.addTime"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="发车时间"
          ></el-time-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="timeDialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="saveTime"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  // queryDispatchPlanList,
  realQueryBusPositionsAPI,
  queryLineDetailByCompanyIds,
  queryAllBusLineList,
  getBusBycompanyIds,
  getLineAndStationsByCompanyIds,
  queryVehicleStatisticsAPI,
  queryLineTripsStatisticsAPI,
  queryPlanTimelyAPI,
  queryDispatchPlanAPI,
  batchUpdateWorkTriptAPI,
  updateWorkTripAPI,
  queryBusPositionsAPI,
  queryLineTodayOfflinePositionsAPI
} from "@/api/lib/bus-api.js";
import AMap from "AMap";
export default {
  props: {
    lineCode: {
      type: Number,
      default: null
    },
    activeName: {
      type: String,
      default: ""
    },
    containHeight:{
      type:String,
      default:"calc(44vh + 16px)"
    }    
  },
  data () {
    return {
      currentMap: "simulate",
      selfPage: false,
      timer_zf: null,
      timer_info: null,
      ulWidth: 0,
      dispatchPlanList: [],
      dispatchStatisticsData: {
        online: 0,
        operate: 0,
        matching: 0,
        notOperateList: []
      }, //车辆调度统计
      hintOptions: [
        {
          url: require("@/assets/images/busIcon/bus_map_nor.png"),
          val: "正常",
          state: 0
        },
        {
          url: require("@/assets/images/busIcon/bus_map_leave.png"),
          val: "离线",
          state: -1
        },
        {
          url: require("@/assets/images/busIcon/bus_map_err.png"),
          val: "维修",
          state: 6
        },
        {
          url: require("@/assets/images/busIcon/bus_map_aerate.png"),
          val: "加气",
          state: 4
        },
        // {
        //   url: require("@/assets/images/busIcon/bus_map_offline.png"),
        //   val: "脱线",
        //   state: 999
        // }
      ],
      fuToZhuOptions: [],
      zhuTofuOptions: [],
      downBus: [],
      upBus: [],
      downStationSpace: 0,
      upStationSpace: 0,
      // 添加唯一标识,在组件销毁时区分不同组件的dropdown避免误删
      uniquelyIdentify: Number(
        Math.random()
          .toString()
          .substr(3, 5) + Date.now()
      ).toString(36),
      timeNum: 100, //倒计时
      map: null,
      busOptions: [],
      marker_bus: [],
      marker_label: [],
      busIds: [],
      timer_bus: null,
      businessType: ["", "上", "下", "环"],
      text: null, //站点hover text
      stationIcon: [
        require("@/assets/images/bus/master.png"),
        require("@/assets/images/bus/slaver.png")
      ],
      vehicleStatistics: {
        vehicleCount: 0,
        operateCount: 0,
        notOperateCount: 0,
        upCount: 0,
        downCount: 0
      },
      lineTripsStatistics: {
        planCount: 0,
        completeCount: 0,
        notCompleteCount: 0
      },
      planTimelyData: {
        startPunctual: 0,
        endPunctual: 0
      },
      masterDrag: false,
      slaverDrag: false,
      timeDialog: false, //修改时间弹窗
      form: {
        addTime: null
      },
      rules: {
        addTime: [{ required: true, message: "不能为空", trigger: "change" }]
      },
      changeObj: null, //修改班次的对象
      lineTodayOfflineCarData:"",//线路离线车辆
    };
  },

  watch: {
    lineCode (n, o) {
      this.masterDrag = false;
      this.slaverDrag = false;    
      this.currentMap == "simulate" && this.getBusInfo(); //实时信息
      this.getbiscData();//基础数据
      this.currentMap == "corpuscle" && this.changeLine(); //地图
      this.getqueryLineDetailByCompanyIds(); //线路详情
      this.getqueryDispatchPlan(); //查询主副场即将发车的计划列表-定时请求
      this.queryLineTodayOfflinePositions();//线路离线车辆列表
    },
    fuToZhuOptions (val) {
      if (val.length > 1) {
        const width = this.ulWidth - 20;
        this.downStationSpace = width / (val.length - 1);
      }
    },
    zhuTofuOptions (val) {
      if (val.length > 1) {
        const width = this.ulWidth - 20; //减去图标盒子的宽度
        this.upStationSpace = width / (val.length - 1);
      }
    }
  },
  mounted () {
    this.ulWidth = this.$refs.upUl.clientWidth;
    this.loadmap();
    this.loadMapTool();
  },
  destroyed () {
    this.destroyDropDowns();
    clearInterval(this.timer_bus);
    clearInterval(this.timer_zf);
    clearInterval(this.timer_bus);
  },
  methods: {
    queryLineTodayOfflinePositions(){
      queryLineTodayOfflinePositionsAPI({companyId:this.lineCode}).then(res=>{
        if(res.code===1000){          
          let arr = [];
          res.data.forEach(item=>{
            arr.push(item.cph);
          })
          this.lineTodayOfflineCarData = arr.join(",");
        }else{
          this.lineTodayOfflineCarData = "";
        }
      })
    },
    //刷新页面-父组件调用
    refreshView () {
      this.currentMap == "simulate" && this.getBusInfo(); //实时信息
      this.currentMap == "corpuscle" && this.changeLine(); //地图
      this.getqueryDispatchPlan(); //查询主副场即将发车的计划列表-定时请求
    },
    handleChangeTime (time, index, num) {
      //num区分主副场
      this.form.addTime = time;
      if (num == 1) this.changeObj = this.dispatchPlanList.masterPlan[index]; //主场
      if (num == 2) this.changeObj = this.dispatchPlanList.slaverPlan[index];
      this.timeDialog = true;
    },
    saveTime () {
      const { id, vehicleNo, cph, credentialsNo, deviceNo } = this.changeObj;
      let data = {
        cheCiId: id,
        newBeginTime: this.form.addTime,
        vehicleNo,
        cph,
        credentialsNo,
        deviceNo
      };
      this.$refs.form.validate(valid => {
        if (valid) {
          updateWorkTripAPI(data).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
              this.timeDialog = false;
              this.getqueryDispatchPlan();
            } else {
              this.$message.warning(res.msg);
            }
          });
        }
      });
    },
    submitDrag () {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "是否要保存修改？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        console.log("拖拽结束：", this.dispatchPlanList.masterPlan);
        console.log("拖拽结束shijian：", this.dispatchPlanList.masterPlanTime);
        let data = [];
        this.dispatchPlanList.masterPlan.map((item, index) => {
          data.push({
            cheCiId: item.id,
            newBeginTime: this.dispatchPlanList.masterPlanTime[index].beginTime,
            vehicleNo: item.vehicleNo,
            cph: item.cph,
            credentialsNo: item.credentialsNo,
            deviceNo: item.deviceNo
          });
        });
        batchUpdateWorkTriptAPI({
          tripList: data
        }).then(res => {
          console.log("------true");
          if (res.code === 1000) {
            this.$message.success(res.msg);
            this.getqueryDispatchPlan();
            this.slaverDrag = false;
            this.masterDrag = false;
          }
        });
      });
    },
    submitDragSlaver () {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "是否要保存修改？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        let data = [];
        this.dispatchPlanList.slaverPlan.map((item, index) => {
          data.push({
            cheCiId: item.id,
            newBeginTime: this.dispatchPlanList.slaverPlanTime[index].beginTime,
            vehicleNo: item.vehicleNo,
            cph: item.cph,
            credentialsNo: item.credentialsNo,
            deviceNo: item.deviceNo
          });
        });
        batchUpdateWorkTriptAPI({
          tripList: data
        }).then(res => {
          console.log("------true");
          if (res.code === 1000) {
            this.$message.success(res.msg);
            this.getqueryDispatchPlan();
            this.slaverDrag = false;
            this.masterDrag = false;
          }
        });
      });
    },
    getbiscData () {
      let companyId = this.lineCode;
      //车辆营运统计
      queryVehicleStatisticsAPI({ companyId }).then(res => {
        if (res.code === 1000) {
          const {
            vehicleCount,
            operateCount,
            notOperateCount,
            upCount,
            downCount
          } = res.data;
          this.vehicleStatistics = {
            vehicleCount,
            operateCount,
            notOperateCount,
            upCount,
            downCount
          };
        }
      });
      //车次完成情况
      queryLineTripsStatisticsAPI({ companyId }).then(res => {
        if (res.code === 1000) {
          const { planCount, completeCount, notCompleteCount } = res.data;
          this.lineTripsStatistics = {
            planCount,
            completeCount,
            notCompleteCount
          };
        }
      });
      //准点率统计
      queryPlanTimelyAPI({ companyId }).then(res => {
        if (res.code === 1000) {
          const { startPunctual, endPunctual } = res.data;
          this.planTimelyData = {
            startPunctual,
            endPunctual
          };
        }
      });
    },
    loadmap () {
      let map = new AMap.Map("container", {
        center: [105.855405, 32.338245],
        zoom: 10
      });
      this.map = map;
    },
    loadMapTool () {
      let self = this;
      self.map.plugin(["AMap.ToolBar"], function () {
        self.toolBar = new AMap.ToolBar({
          visible: true,
          position: "RB",
          locate: true,
          liteStyle: true
        });
        self.map.addControl(self.toolBar);
      });
    },
    //拖拽互换位置
    handleDragStart (e, items) {
      this.dragging = items; //开始拖动时，暂时保存当前拖动的数据。
    },
    handleDragEnd (e, items) {
      this.dragging = null; //拖动结束后，清除数据
    },
    handleDragOver (e) {
      e.dataTransfer.dropEffect = "move"; //在dragenter中针对放置目标来设置!
    },
    handleDragEnter (e, items) {
      e.dataTransfer.effectAllowed = "move"; //为需要移动的元素设置dragstart事件
      if (items == this.dragging) return;
      var newItems = [...this.dispatchPlanList.masterPlan]; //拷贝一份数据进行交换操作。
      var src = newItems.indexOf(this.dragging); //获取数组下标---拖动元素
      console.log("获取数组下标", newItems[src]);
      var dst = newItems.indexOf(items); //下标---目标元素
      console.log("****************", dst);
      newItems.splice(dst, 0, ...newItems.splice(src, 1)); //交换位置
      this.dispatchPlanList.masterPlan = newItems;
      console.log("拖拽结束：", this.dispatchPlanList.masterPlan);
      console.log("拖拽结束shijian：", this.dispatchPlanList.masterPlanTime);
      this.masterDrag = true;
      return;
      //只挪动车牌号，不挪动时间------只能挪动一个位置
      newItems.map((element, index) => {
        let temp = "";
        if (src === index) {
          temp = element.cph;
          element.cph = newItems[dst].cph;
          newItems[dst].cph = temp;
        }
      });
      this.dispatchPlanList.masterPlan = newItems;
    },
    //拖拽互换位置
    handleDragStart2 (e, items) {
      this.dragging2 = items; //开始拖动时，暂时保存当前拖动的数据。
    },
    handleDragEnd2 (e, items) {
      this.dragging2 = null; //拖动结束后，清除数据
    },
    handleDragOver2 (e) {
      e.dataTransfer.dropEffect = "move"; //在dragenter中针对放置目标来设置!
    },
    handleDragEnter2 (e, items) {
      e.dataTransfer.effectAllowed = "move"; //为需要移动的元素设置dragstart事件
      if (items == this.dragging2) return;
      var newItems = [...this.dispatchPlanList.slaverPlan]; //拷贝一份数据进行交换操作。
      var src = newItems.indexOf(this.dragging2); //获取数组下标---拖动元素
      console.log("获取数组下标", newItems[src]);
      var dst = newItems.indexOf(items); //下标---目标元素
      console.log("****************", dst);
      newItems.splice(dst, 0, ...newItems.splice(src, 1)); //交换位置
      this.dispatchPlanList.slaverPlan = newItems;
      console.log("拖拽结束2：", this.dispatchPlanList.slaverPlan);
      console.log("拖拽结束shijian2：", this.dispatchPlanList.slaverPlanTime);
      this.slaverDrag = true;
    },
    //切换模拟视图和电子地图
    switchMAp (name) {
      if (name == this.currentMap) return;
      if (this.timer_info) clearInterval(this.timer_info);
      if (this.timer_bus) clearInterval(this.timer_bus);
      this.currentMap = name;
      this.destroyDropDowns();
      if (name == "simulate") {
        this.getBusInfo();
      } else {
        this.changeLine();
      }
    },
    /* 根据线路查询车辆（多选） */
    changeLine () {
      if (!this.lineCode) {
        this.busOptions = [];
        this.busIds = [];
        this.clearBusMarker();
      } else {
        this.busIds = [];
        getBusBycompanyIds({ companyIds: [this.lineCode] }).then(res => {
          if (res.data.length > 0) {
            let carData = [];
            res.data.map(
              value => (carData = [...carData, ...value.vehicleList])
            );
            this.busOptions = carData;
            this.$bus.$emit("loadingUpdate",true);
            this.getLineAndStations();
            this.showBus();
            this.marker_bus = [];
            this.marker_label = [];
          }
        });
      }
    },
    /* 获取站点和线路信息 */
    getLineAndStations () {
      var self = this;
      self.clearBusMarker();
      getLineAndStationsByCompanyIds({ companyIds: [this.lineCode] }).then(
        res => {
          self.drawStation(res);
          self.drawPath(res);
          setTimeout(function () {
            self.$bus.$emit("loadingUpdate",false);
          }, 1000);
        }
      );
    },
    /**清除地图上显示和缓存车辆marker数据 */
    clearBusMarker () {
      this.reqstatus = 0;
      clearInterval(this.timer_bus);
      this.map.clearMap();
      this.marker_bus = [];
      this.marker_label = [];
      this.map.remove(this.marker_label);
      this.map.remove(this.marker_bus);
    },
    /* 绘制线路 */
    drawPath (res) {
      for (let i = 0; i < res.data.length; i++) {
        let element = res.data[i];
        if (element.lineMappingList && element.lineMappingList.length > 0) {
          for (let j = 0; j < element.lineMappingList.length; j++) {
            const value = element.lineMappingList[j];
            if (value.coordinates.indexOf("[{") > -1) {
              let { color, width, pellucidity } = value;
              let data = JSON.parse(value.coordinates);
              this.renderPolyline(data, color, width, pellucidity);
            }
          }
        }
      }
    },
    //上下行线路数据
    renderPolyline (pathArr, color, width, pellucidity) {
      var polyline = new AMap.Polyline({
        // 设置线覆盖物路径
        path: pathArr,
        // 线颜色
        strokeColor: color || "#5298ff",
        strokeOpacity: pellucidity || 0.8,
        // 线宽
        strokeWeight: width || 6,
        strokeDasharray: [25, 10],
        lineJoin: "round",
        lineCap: "round",
        cursor: "default"
      });

      this.map.add([polyline]);
      this.map.setFitView();
    },
    /* 绘制站点 */
    drawStation (res) {
      for (let i = 0; i < res.data.length; i++) {
        let element = res.data[i];
        let flag = false;
        //下行
        if (element.downStationList) {
          flag = true;
          this.renderStation(element.downStationList);
        }
        //上行
        if (element.upStationList) {
          flag = true;
          this.renderStation(element.upStationList);
        }

        if (flag) {
          let startMarker = new AMap.Marker({
            position: [element.masterLongitude, element.masterLatitude],
            icon: this.stationIcon[0],
            offset: new AMap.Pixel(-5, -4),
            zIndex: 15
          });
          let endMarker = new AMap.Marker({
            position: [element.slaverLongitude, element.slaverLatitude],
            icon: this.stationIcon[1],
            offset: new AMap.Pixel(-5, -4),
            zIndex: 15
          });
          this.map.add([startMarker, endMarker]);
        }
      }
    },
    /* 绘制站点 */
    renderStation (stationArr, moveType) {
      let self = this;
      stationArr.map((item, i) => {
        let content = "";
        if (item.latitude) {
          content = `<div style='width:5px;height:5px;border-radius:50%;background-color:#FFFFFF;border:2px solid #0B4FD5'></div>`;
        }
        let marker = new AMap.Marker({
          content: content,
          position: [item.longitude, item.latitude],
          offset: new AMap.Pixel(-5, -4),
          zIndex: 15
        });
        marker.on("mouseover", function (e) {
          self.text = new AMap.Text({
            map: self.map,
            position: [item.longitude, item.latitude],
            text: item.stationName,
            offset: new AMap.Pixel(0, -12)
          });
        });
        marker.on("mouseout", function (e) {
          self.map.remove(self.text);
        });

        marker.setMap(self.map);
      });
    },
    /* 展示车辆 */
    showBus () {
      let self = this;
      if (self.timer_bus) {
        self.clearBusMarker();
      }
      let timerFn_bus = function () {
        queryBusPositionsAPI({
          companyIds: [self.lineCode],
          vehicleNos: self.busIds
        }).then(res => {
          if (res.data.length > 0) {
            //根据经纬度绘制车辆
            self.renderCarMarker(res.data);
          }
        });
      };
      timerFn_bus();
      self.reqstatus += 1;
      self.timer_bus = setInterval(() => {
        timerFn_bus();
      }, 10000);
    },
    /**显示200及以下个车辆信息 */
    renderCarMarker (points) {
      let self = this;
      for (let i = 0; i < points.length; i++) {
        let lnglat = new AMap.LngLat(points[i].longitude, points[i].latitude);
        let { businessType, status, cph } = points[i];
        if(!status && status !==0 ) status = 1;//默认离线
        let content = `<div style="display:flex;justify-content: space-around;align-items: center;">
        <span>${cph}</span>
        <span style="width: 22px;height:22px;margin-left:5px;text-align:center;color:#333;background: #f78d4233;">${self.businessType[businessType] ? self.businessType[businessType] : ""
          }</span></div>`;
        //车辆makerMove
        if (points[i].longitude && points[i].latitude) {
          if (self.marker_bus[i] && self.reqstatus >= 1) {
            self.marker_bus[i].moveTo(lnglat, 5000, function (k) {
              return k;
            });
            const newIcon = new AMap.Icon({
              size: new AMap.Size(26, 26), // 图标尺寸
              image: self.hintOptions[status]?.url, // Icon的图像
              imageSize: new AMap.Size(26, 26)
            });
            self.marker_bus[i].setIcon(newIcon);
            self.marker_label[i].moveTo(lnglat, 5000, function (k) {
              return k;
            });
            self.marker_label[i].setText(content);
          } else {
            //车辆makerSet
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: self.hintOptions[status]?.url, // Icon的图像
                imageSize: new AMap.Size(26, 26)
              }),
              zIndex: 999,
              topWhenClick: true,
              position: lnglat,
              offset: new AMap.Pixel(-10, -5)
            });
            let text = new AMap.Text({
              map: self.map,
              position: lnglat,
              text: content,
              offset: new AMap.Pixel(75, -2),
              style: {
                "font-size": "14px",
                border: "none",
                padding: "6px 10px",
                "background-color": "#fff",
                "box-shadow": "0px 2px 8px 0px rgba(200, 201, 204, 0.5)",
                "line-height": "20px"
              }
            });
            let mask = points[i];
            // console.log(3333, points[i]); //经纬度没变
            text.on("click", function (e) {
              // 展示当前点击车辆信息
              self.showCarDetailMap(mask, [e.lnglat.lng, e.lnglat.lat]);
            });
            marker.on("click", e => {
              // console.log('mask222',mask)
              // 展示当前点击车辆信息
              self.showCarDetailMap(mask, [e.lnglat.lng, e.lnglat.lat]);
            });
            self.marker_label.push(text);
            marker.setMap(self.map);
            self.marker_bus.push(marker);
          }
        }

      }
    },
    //显示车辆详细信息
    showCarDetailMap (point, lnglat) {
      // console.log(2222,[point.longitude, point.latitude])
      let content = `<div>车牌号：<span>${point.cph}</span></div>
                    <div>设备号：<span>${point.deviceNo}</span></div>
                    <div>司机：<span>${point.driverName}</span></div>
                    <div>当前行驶速度：<span>${point.velocity}km/h</span></div>
                    <div>行驶角度：<span>${point.direction}</span></div>
                    <div>位置最后更新时间：<span>${point.gpsTime}</span></div>
                    <div>所属线路：<span>${point.companyName
        }</span></div>                    
                    <div>调度屏线路：<span>${point.operateLineName
        }</span></div>                    
                    <div>站点序号：<span>${point.stationNum ? point.stationNum : ""}</span></div>
                    <div>当前站点：<span>${point.stationName ? point.stationName : ""}</span></div>
                    <div>业务方向：<span>${point.businessType ? (point.businessType == 1 ? "上行" : "下行" ) :"" }</span></div>
                    `;
      let infoWindow = new AMap.InfoWindow({
        anchor: "bottom-left",
        content: `<div class=trailBoard>${content}</div> `
      });
      // infoWindow.open(this.map, [point.longitude, point.latitude]);
      infoWindow.open(this.map, lnglat);
    },
    // 销毁dropdown
    destroyDropDowns () {
      const destroyNodes = document.querySelectorAll(
        'ul[uniquelyIdentify="' + this.uniquelyIdentify + '"]'
      );
      if (destroyNodes.length != 0) {
        Array.from(destroyNodes).forEach(e => {
          document.body.removeChild(e);
        });
      }
    },
    // 点击dropdown
    onDropDownClick (event) {
      let dom = event.target.parentNode.parentNode.parentNode.children[1];
      dom.style.display =
        dom.style.display == "" || dom.style.display == "flex"
          ? "none"
          : "flex";
      this.drawDropdowns();
    },
    // 绘制dropdown
    drawDropdowns () {
      this.destroyDropDowns();
      // 上行
      const down = this.$refs.down.children;
      Array.from(down).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top - e.children[1].clientHeight - 8}px`;
        dd.style.left = `${left + 0.5 * w - 1.8 * e.children[1].clientWidth}px`;
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
      // 下行
      const up = this.$refs.up.children;
      Array.from(up).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top + h + 10}px`; //车牌对应图标的距离
        dd.style.left = `${left + 0.5 * w - 1.8 * e.children[1].clientWidth}px`; //车牌对应图标的距离
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
      this.$bus.$emit("loadingUpdate",false);
    },
    // 点击车牌号显示弹窗
    onCarClick (event) {
      let dom = event.target;
      if (dom.getAttribute("vehicleNo"))
        this.showCarDetail(dom.getAttribute("vehicleNo"));
    },
    // 点击车牌号显示弹窗
    showCarDetail (vehicleNo) {
      this.$emit("showCarDetail", vehicleNo);
    },
    //指令下发
    issueInstruction (name, id) {
      this.$emit(name, id);
    },
    /* 获取车辆信息 */
    getBusInfo () {
      let self = this;
      if (this.timer_info) clearInterval(this.timer_info);
      let timerFn = function () {
        realQueryBusPositionsAPI({ companyId: self.lineCode }).then(res => {
          if (res.code === 1000) {
            self.$bus.$emit("loadingUpdate",true);
            // businessType => 1上行（副到主）2下行（主到副）
            //后端数据返的有离站的数据，因此需要过滤掉stationType站点类型为2的数据
            const down = res.data.filter(item => item.businessType == "2" && item.stationType != '2');
            let downArr = [];
            down.forEach(item => {
              if (downArr.length == 0) {
                downArr.push({
                  stationNo: item.stationNum,
                  arriveType: item.arriveType,
                  busCodes: [
                    {
                      busCode: item.cph,
                      color: item.vehicleColor,
                      status: item.status,
                      vehicleNo: item.vehicleNo
                    }
                  ]
                });
              } else {
                let flag = false;
                downArr.forEach(arr => {
                  if (
                    item.stationNum == arr.stationNo &&
                    item.arriveType == arr.arriveType
                  ) {
                    flag = true;
                    arr.busCodes = [
                      ...arr.busCodes,
                      {
                        busCode: item.cph,
                        color: item.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ];
                  }
                });
                if (!flag) {
                  downArr.push({
                    stationNo: item.stationNum,
                    arriveType: item.arriveType,
                    busCodes: [
                      {
                        busCode: item.cph,
                        color: item.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ]
                  });
                }
              }
            });
            self.downBus = downArr;

            // 上行
            const up = res.data.filter(item => item.businessType == "1" && item.stationType != '2');

            let upArr = [];
            up.forEach(item => {
              if (upArr.length == 0) {
                upArr.push({
                  stationNo: item.stationNum,
                  arriveType: item.arriveType,
                  busCodes: [
                    {
                      busCode: item.cph,
                      color: item.vehicleColor,
                      status: item.status,
                      vehicleNo: item.vehicleNo
                    }
                  ]
                });
              } else {
                let flag = false;
                upArr.forEach(arr => {
                  if (
                    item.stationNum == arr.stationNo &&
                    item.arriveType == arr.arriveType
                  ) {
                    flag = true;
                    arr.busCodes = [
                      ...arr.busCodes,
                      {
                        busCode: item.cph,
                        color: item.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ];
                  }
                });
                if (!flag) {
                  upArr.push({
                    stationNo: item.stationNum,
                    arriveType: item.arriveType,
                    busCodes: [
                      {
                        busCode: item.cph,
                        color: item.vehicleColor,
                        status: item.status,
                        vehicleNo: item.vehicleNo
                      }
                    ]
                  });
                }
              }
            });
            self.upBus = upArr;
            setTimeout(() => {
              self.drawDropdowns();
            }, 200);            
          } else {
            self.downBus = [];
            self.upBus = [];
            self.destroyDropDowns();
          }
        });
      };
      //立即执行一次
      timerFn();

      //设置定时器
      self.timer_info = setInterval(() => {
        timerFn();
      }, 1000 * this.timeNum);
    },
    //线路详情
    getqueryLineDetailByCompanyIds () {
      queryLineDetailByCompanyIds({ companyIds: [this.lineCode] })
        .then(res => {
          this.$bus.$emit("loadingUpdate",true);
          let data = res.data[0];
          if (data && data.downStationList) {
            this.zhuTofuOptions = data.downStationList; //下行
          } else {
            this.zhuTofuOptions = [];
          }
          if (data && data.upStationList) {
            this.fuToZhuOptions = data.upStationList.reverse(); //上行
          } else {
            this.fuToZhuOptions = [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    //查询主副场即将发车的计划列表
    getqueryDispatchPlan () {
      if (this.timer_zf) clearInterval(this.timer_zf);
      const intervalIimerFn = () => {
        queryDispatchPlanAPI({ companyId: this.lineCode })
          .then(res => {
            // this.dispatchPlanList = res.data;
            //把时间字段单独提出来，保持不变，不可拖动
            let timeArr = [];
            let timeArr1 = [];
            if (res.data.masterPlan && res.data.masterPlan.length) {
              res.data.masterPlan.forEach(item => {
                timeArr.push({
                  beginTime: item.beginTime,
                  overTime: item.overTime
                });
              });
              res.data.masterPlanTime = [...timeArr];
            }
            if (res.data.slaverPlan && res.data.slaverPlan.length) {
              res.data.slaverPlan.forEach(item => {
                timeArr1.push({
                  beginTime: item.beginTime,
                  overTime: item.overTime
                });
              });
              res.data.slaverPlanTime = [...timeArr1];
            }
            this.$nextTick(() => {
              this.dispatchPlanList = res.data;
            });
          })
          .catch(err => {
            console.log(err);
          });
      };
      intervalIimerFn();
      this.timer_zf = setInterval(() => {
        intervalIimerFn();
      }, 1000 * this.timeNum);
    },
    /* 车辆图标 */
    getBusIcon (status) {
      if (status == 0 || status == undefined) {
        return "bus-norm bus-icon";
      }
      if (status == 1 || status == -1) {
        return "bus-offline bus-icon";
      }
      if (status == 2) {
        return "bus-repair bus-icon";
      }
      if (status == 3) {
        return "bus-aerate bus-icon";
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer_info);
    clearInterval(this.timer_zf);
  }
};
</script>
<style lang="scss" scoped>
#container {
  height: 100%;
  width: 100%;
}
.inner-box {
  text-align: center;
  .inner-item {
    line-height: 28px;
    cursor: pointer;
  }
}
.simulation-map-box {
  font-size: 12px;
  margin: 7px 0 10px;
  // height: calc(49vh + 16px);
  // height: calc(44vh + 16px);
  display: flex;
  .simulation-map-between {
    width: 140px;
    height: 40px;
    background: #f7f8f9;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    .submit-btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .title {
      line-height: 40px;
      text-align: center;
    }
    .main-line {
      line-height: 34px;
      font-size: 12px;
      display: flex;
      border: 1px solid #dcdedf;

      .item-cph {
        flex: 1;
      }
      .list-car {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          width: 16px;
          height: 16px;
          margin-left: 10px;
        }
      }
      .list-car:not(:last-child) {
        border-bottom: 1px solid #dcdedf;
      }
      .cph {
        margin: 0 4px 0 4px;
        cursor: pointer;
        display: inline-block;
        min-width: 50px;
        text-align: left;
      }
      .cph:hover {
        color: #f48b41;
      }
      .cph:focus {
        color: #f48b41;
      }
      .time {
        color: #969799;
        cursor: pointer;
      }
      .overtime {
        color: #f74242;
        cursor: pointer;
      }
    }
  }
  .left {
    border: 1px solid #9ab6fb;
    height: 100%;
    .title {
      background: #9ab6fb;
    }
  }
  .right {
    border: 1px solid #ffb658;
    height: 100%;
    .title {
      background: #f78d42;
    }
  }
  .simulation-map-center {
    flex: 1;
    background: #f2f3f4;
    border: 1px solid #dcdedf;
    box-sizing: border-box;
    .center-logo {
      background: rgba(247, 141, 66, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      line-height: 40px;
      .online {
        flex: 1;
        padding-left: 35px;
        display: flex;
        overflow: hidden;
        .car-data {
          margin-right: 24px;
          white-space: nowrap;
        }
        span {
          color: #354764;
        }
        .num-value {
          margin-left: 8px;
        }
      }
      .online:hover {
        overflow: auto;
      }
      .name {
        width: 74px;
        background: #f78d42;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
      }
      .menus {
        display: flex;
        padding: 9px 12px;
        .simulate-btn {
          border-radius: 4px 0 0 4px;
        }
        .corpuscle-btn {
          border-radius: 0 4px 4px 0;
        }
        .map-menu {
          padding: 2px 11px;
          background: #e1e2e2;
          color: #101828;
          font-size: 14px;
          line-height: 22px;
          cursor: pointer;
        }
        .menu-active {
          background: #f78d42;
          color: #ffffff;
        }
      }
    }
    .map-notOperateList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      background: rgba(220, 222, 223, 0.3);
      // line-height: 26px;
      height: 26px;
      .other-cars {
        margin-left: 12px;
        cursor: pointer;
      }
      .logo {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;
        .item {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
          font-size: 12px;
          margin-right: 10px;
          color: #333;
        }
      }
    }
    .switch-contain {
      height: calc(100% - 66px);//40 + 26
    }
    // 模拟视图
    .simulation-map {
      height: 100%;
      .up,
      .down {
        height: 50%;
        position: relative;
        .line-center {
          height: 4px;
          background: #c4d2f3;
          position: absolute;
          width: 100%;
          top: 48%;
        }
        ul {
          width: 88%;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          height: 50%;
          position: relative;
          top: 46%;
          li {
            width: 20px;
            font-size: 12px;
            display: inline-block;
            position: relative;
            display: flex;
            flex-direction: column;
            color: #666666;
            i {
              z-index: 9;
              display: inline-block;
              content: '';
              background-color: #fff;
              height: 8px;
              width: 8px;
              border-radius: 50%;
              border: 2px solid #afc3f2;
              position: absolute;
              left: 50%;
              transform: translate(-50%);
            }
            span {
              height: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              text-orientation: upright;
              white-space: nowrap;
              writing-mode: vertical-lr;
              position: absolute;
              width: 16px;
              cursor: pointer;
            }
          }
        }
      }
      .up {
        ul {
          left: 6%;
        }
        li:nth-child(odd) {
          span {
            transform: rotate(45deg) translate(-130%, 31%);
          }
        }
        li:nth-child(even) {
          transform: rotate(180deg) translateY(-12px);
          top: -100%;
          span {
            transform: rotate(137deg) translate(-156%, -35%);
            text-align: right;
          }
        }
      }
      .down {
        ul {
          left: 6%;
        }

        li:nth-child(odd) {
          span {
            transform: rotate(-45deg) translate(150%, 31%);
          }
        }
        li:nth-child(even) {
          transform: rotate(180deg) translateY(-12px);
          top: -100%;
          span {
            // transform:rotate(45deg) translateX(-143%);
            transform: rotate(-137deg) translate(141%, -30%);
            text-align: right;
          }
        }
      }
    }
    //电子地图
    .corpuscle-map {
      height: 100%;
    }
  }
}
.cus-dropdown {
  display: inline-block;
  position: absolute;
  .dropdown-list {
    visibility: hidden;
    width: 4vw;
    height: auto;
    padding: 0.5vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    z-index: 8;
    li {
      width: 4vw;
      text-align: center;
      margin-bottom: 0.5vh;
      cursor: pointer;
    }
  }
  .dropdown-up {
    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-left: 0.5vh solid transparent;
      border-right: 0.5vh solid transparent;
      border-top: 0.75vh solid #ffffff;
      bottom: -0.75vh;
    }
  }
  .dropdown-down {
    &::before {
      position: absolute;
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-left: 0.5vh solid transparent;
      border-right: 0.5vh solid transparent;
      border-bottom: 0.75vh solid #ffffff;
      top: -0.75vh;
    }
  }
  //车辆
  .bus-down {
    width: 60px;
    position: absolute;
    text-align: center;
    z-index: 99;
    bottom: 2.7vh;
    font-size: 12px;
  }
  .bus-up {
    width: 60px;
    position: absolute;
    text-align: center;
    z-index: 99;
    top: -0.5vh;
    left: -0.25%;
    font-size: 12px;
  }
}
.bus-icon {
  display: inline-block;
  content: '';
  height: 20px;
  width: 20px;
}
.bus-norm {
  background: url('../../../../../assets/images/busIcon/bus_map_nor.png')
    no-repeat;
  background-size: 100%;
}

.bus-offline {
  background: url('../../../../../assets/images/busIcon/bus_map_leave.png')
    no-repeat;
  background-size: 100%;
}

.bus-aerate {
  background: url('../../../../../assets/images/busIcon/bus_map_aerate.png')
    no-repeat;
  background-size: 100%;
}

.bus-repair {
  background: url('../../../../../assets/images/busIcon/bus_map_err.png')
    no-repeat;
  background-size: 100%;
}

div[class*='bus-'] {
  width: 100%;
  height: 8vh;
  // background: rgba($color: #4b8dfd, $alpha: 0.1);
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  .line {
    flex: 1;
    height: 24px;
    position: relative;
  }
}
</style>
<!-- 删除会不显示，显示会错位 -->
<style lang="scss">
.dropdown-list {
  width: 3vw;
  height: auto;
  padding: 0.5vh 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba($color: #4b8dfd, $alpha: 0.2);
  border-radius: 4px;
  li {
    // width: 3vw;
    text-align: center;
    margin-bottom: 0.5vh;
    font-size: 12px;
    cursor: pointer;
  }
}
.dropdown-up {
  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-top: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    bottom: -0.75vh;
  }
}

.dropdown-down {
  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-bottom: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    top: -0.75vh;
  }
}
</style>
